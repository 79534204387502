<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Trauma e adoecimento em crianças e adolescentes atingidas"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  O dia do rompimento da Barragem de Fundão é um marco na
                  narrativa das crianças e adolescentes. O acontecimento é
                  relatado com tristeza, angústia, preocupações e incertezas
                  diante do futuro. A vida como conheciam não pode ser
                  continuada nem reconstruída. Este momento é rememorado com
                  muita dor e tristeza, pois passaram a conviver com perdas de
                  toda ordem.
                </p>
                <p>
                  Abaixo transcrevemos um trecho de uma das tomadas de termo de
                  um jovem<sup>1</sup> que esteve acompanhado da mãe durante a
                  etapa. Mantivemos um trecho longo da citação, pois essa fala
                  aponta para diversos aspectos dos danos sofridos pelos jovens
                  que consideramos importantes de destacar.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_17.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Tainara Torres / Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col cols="12" md="8">
          <div
            class="d-flex justify-center align-center border__image3 mt-10"
            style="z-index: 10;"
          >
            <p style="z-index: 15;" class="ma-0 white--text">
              Segundo sua mãe, antes do rompimento da barragem, ele tinha uma
              rotina diária intensa de atividades, jogava bola, nadava, andava
              de bicicleta, trabalhava no cuidado com as galinhas. Após o
              rompimento veio morar numa casa sem espaço livre, fechada. Longe
              dos parentes e amigos de infância. Com isso, devido à
              introspecção, sem amigos, se tornou anti social, se mantém mais em
              casa, não tem interesse por rede social, jogos eletrônicos ou TV.
              Tem muito apetite e está acima do peso. Se sente muito triste
              todos os dias; que chegou a ir ao psicólogo por uma vez no posto
              destinado aos atingidos, mas não quis voltar; que tem muitas
              dificuldades para falar sobre seus sentimentos e acha que o
              rompimento da barragem que lhe trouxe esse sentimento de tristeza
              porque quando morava na roça, não sentia isso; que aqui em Mariana
              ele não tem vontade de sair de casa e sua tristeza lhe faz ficar
              preso dentro do quarto; que na comunidade ele gostava de sair e
              viver livre; o atingido relatou que vem algumas psicólogas até sua
              casa mas não as conhece porque sempre que elas estão em sua
              residência ele está trancando dentro do quarto; que o fato de a
              casa sempre estar cheia de profissionais da Renova, Cáritas e
              Ministério Público, psicólogos, assistentes sociais o incomoda
              muito, pois acha que isso atrapalha a sua privacidade. O atingido
              ao ser perguntado pela entrevistadora sobre o que faria após a
              finalização desta tomada de termo respondeu que iria para dentro
              do quarto e que também passaria o fim de semana dentro do quarto;
              o atingido informou que depois que veio para Mariana engordou por
              volta de 40 quilos e acredita que o fato de não ter ânimo para
              fazer nada e ficar só em casa deitado contribuiu para o ganho de
              peso; que tentou fazer reeducação alimentar e atividade física mas
              deixou de frequentar a academia pois não tem motivação para sair
              de casa. Sexo masculino, 15 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_18.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Escola de Paracatu de Baixo com as marcas da lama. Foto: Flora
            Passos, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  No que se refere à saúde psíquica, notam-se relatos frequentes
                  de angústia, perda ou aumento de apetite, obesidade,
                  distúrbios do sono, ansiedade, preocupações, receio de
                  contaminação e apatia. Com menor frequência — mas de extrema
                  significância —, também foi possível identificar ideação
                  suicida entre algumas crianças e adolescentes.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Fez acompanhamento psicológico por cerca de seis meses. Segundo sua
            mãe, após o rompimento passou a apresentar dificuldades para
            aprender o conteúdo ensinado na escola. Come compulsivamente e
            aumentou muito de peso depois da tragédia. A criança diz que come
            porque quando sente medo tem necessidade de morder ou comer alguma
            coisa. Diz ainda que sente muito medo de muitas coisas, que tem
            pesadelos com a lama. Sonha que está morrendo e que seus pais também
            estão morrendo [...] e às vezes acorda à noite para vomitar porque
            come escondido; sua genitora disse que às vezes está na cozinha
            procurando alguma coisa para comer de madrugada. A criança relata
            que quer “muitoooo” ir para o reassentamento; que toma remédio, mas
            que precisa tomar todos os dias, mas que às vezes sua mãe esquece de
            dar. Sexo feminino, 5 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Antes do rompimento da barragem me sentia bem, não tinha problemas
            de saúde. Após o rompimento da barragem não tive problemas de saúde
            física, mas senti vontade de me suicidar, sinto muita tristeza e
            vontade de voltar para Paracatu. Não uso medicamento. Sofro de ver
            meus irmãos passando por problemas psicológicos, queria mas não
            consigo ajudá-los. Sexo masculino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Antes do rompimento era bem mais alegre, tinha mais vontade de
            viver. Atualmente estou me segurando para não fazer uma “burrada”
            (com voz embargada) (Começa a chorar) Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Sempre fui tímida, não gostava de lugares muito cheios, mas depois
            de me mudar pra Mariana fiquei com dificuldade de sair de casa e
            agora, na escola, não consigo apresentar trabalhos porque fico muito
            nervosa, tremo, o coração acelera e não consigo falar. Não quero
            procurar um médico pra ajudar, porque não quero ficar remoendo o
            acontecido. Isso atrapalha minha vida social. Sexo feminio, 13 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] sinto que engordei por falta de exercícios físicos e fui
            diagnosticado com colesterol alto, problemas que não tinha antes.
            Hoje tenho que controlar meu peso, tendo inclusive que me matricular
            em uma academia na cidade. Fiquei muito triste após o rompimento,
            sinto que sofri abalos psicológicos, problemas para dormir. Sexo
            masculino, 10 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Após o rompimento, aqui em Mariana, comecei a usar bebidas
            alcoólicas e drogas, mas graças a Deus comecei a frequentar a igreja
            e hoje já não uso mais. [...] Várias vezes cheguei a pensar se valia
            continuar vivendo ou não. Tenho várias marcas de cortes no pulso por
            tentar tirar minha vida. Sexo masculino, 17 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section
      class="container-fluid section__mb stripe_background d-flex justify-center align-center"
    >
      <v-row
        style="z-index: 20;"
        class="container d-flex justify-center align-center;"
      >
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Muitas vezes, por não dar conta de simbolizar aquilo que
                decorreu do acontecimento traumático<sup>2</sup>, o psiquismo
                assume uma função destrutiva ou de adoecimento, assim como
                observamos nessas crianças e adolescentes. A vivência traumática
                pode ser entendida em três tempos<sup>3</sup>: o
                <strong>tempo do indizível</strong>, o tempo do testemunho e o
                tempo do desmentido (KAHTUNI, 2009; KUPERMANN, 2008; 2019).
                Tentaremos nos aproximar dos três momentos na experiência vivida
                pelas crianças e adolescentes atingidos.
              </p>
            </template>
          </Card>

          <Card borderColor="#E06919" class="mt-5">
            <template v-slot:conteudo>
              <p>
                No primeiro tempo, nomeado tempo do indizível, a criança vive o
                ato deflagrador de dor, um horror vivenciado, excesso que não
                tem como ser representado, como por exemplo no dia do rompimento
                da barragem, o barulho da lama se aproximando da sua comunidade.
                São relatos de algo nunca vivenciado antes, um barulho (do
                movimento da lama, destruindo tudo que encontrava pela frente,
                casas, árvores, roças numa dimensão inimaginável) que não tinha
                nenhuma outra equivalência interna, nunca ouvido antes, não
                tinha como ser compreendido e nem se ter a dimensão do que
                estava ocorrendo. É comum esse tipo de conteúdo retornar mais
                tarde em sonhos e pesadelos na tentativa de encontrar o sentido
                ou os sentidos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex justify-space-around">
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <Card borderColor="#E06919" class="">
            <template v-slot:conteudo>
              <p>
                Ainda dentro do tempo do indizível, estava o temor pelos amigos,
                membros da família e comunidade. A angústia de não saber quem
                teria sobrevivido ao desastre também foi uma vivência de horror,
                pois muitos perderam os acessos e ficaram incomunicáveis por
                semanas.
              </p>
              <p>
                No segundo tempo, temos o
                <strong>tempo do testemunho</strong>, em que diante da situação
                de desamparo extremo, busca-se o outro, para ser testemunha do
                que aconteceu. Salientamos que a repercussão nacional e
                internacional do desastre, levando em consideração a gravidade
                da ocorrência, as tomadas de termo, as escutas das crianças
                tiveram relevância na forma como se lidou com esse tempo do
                trauma<sup>4</sup>.
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] tenho muitos pesadelos com a lama, sempre penso muito no que
            aconteceu, sempre converso com meus amigos sobre o dia do
            rompimento, e a gente sempre compara como é morar aqui e como era
            morar na comunidade. [...] Hoje passei a ter medo de morrer. Sexo
            masculino, 11 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div
            class="d-flex justify-center align-center border__image3 mt-10"
            style="z-index: 10;"
          >
            <p style="z-index: 15;" class="ma-0 white--text">
              Após o rompimento meu sono ficou ruim. Ainda vem na mente os
              cavalos se debatendo na lama, o que vi da escola, todos correndo.
              Sempre essas memórias em minha mente e tenho pesadelos. Senti
              mudanças no meu psicológico. Fiquei sem reação, mudou tudo e minha
              mente ficou perturbada. Foi uma mudança de uma hora para a outra,
              a gente tinha que mudar, porque já tinha perdido tudo. Só tenho
              pensamentos ruins. Sexo masculino, 13 anos.
            </p>
          </div>
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  No <strong>tempo do desmentido</strong>, encontramos uma
                  situação em que um terceiro nega todos os efeitos do trauma na
                  vítima, como quando um adulto, o Estado, ou alguma instituição
                  que deveria cuidar e zelar abandona essa vítima. Os efeitos
                  dessa desmentida podem ser um aprisionamento na situação
                  traumática, a apatia e a desesperança, e esse pode ser um dos
                  efeitos mais terríveis de um trauma, pois toda a parte
                  sensível do sujeito fica anestesiada.
                </p>
                <p>
                  Quando são feitos movimentos reparatórios e de elaboração de
                  um trauma, se reacende a esperança e a possibilidade de
                  retomar a vida e o desenvolvimento. No caso contrário, quando
                  não são feitos, ou quanto maior o abandono encontrado por
                  parte da vítima, mais essa ficará refém da pulsão de morte, do
                  adoecimento físico e psíquico. Entre alguns exemplos de tempo
                  do desmentido, podemos citar a carta de inelegibilidade
                  enviada pela Fundação Renova para os atingidos e atingidas,
                  não reconhecendo sua verdade, as baixas indenizações que não
                  consideram os danos imateriais sofridos por essa população e
                  até mesmo a pecha de aproveitadores que paira sobre os
                  atingidos e atingidas desde o desastre–crime.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_19.png" />
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            O tratamento das pessoas aqui de Mariana é muito diferente também,
            se está conversando com alguém e conta que é de Bento, logo pensam
            que ela está rica. Se não tivesse acontecido tudo isso, se viesse
            para Mariana não sofreria tanto preconceito como ocorre após o
            rompimento. Sexo feminino, 17 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  Duas semanas após o desastre–crime<sup>5</sup>, houve em
                  Mariana protestos nas ruas pelo retorno do funcionamento da
                  Samarco com o bordão “Somos Todos Samarco”, inclusive a poucos
                  metros da Arena em que foram inicialmente reunidos e
                  posteriormente instalados de maneira provisória atingidos e
                  atingidas que lograram em chegar à parte urbana. Nos meses e
                  anos seguintes, viam-se no centro faixas com dizeres como “A
                  Cidade de Mariana apoia a retomada da Samarco”. A própria
                  estadia forçosa em equipamentos públicos imediatamente após o
                  rompimento em poucas semanas se tornou motivo de crítica ao,
                  por exemplo, atrasar o período letivo e sobrecarregar as
                  unidades de saúde. É conversa comum pelas ruas de que os
                  atingidos e atingidas são aproveitadores, que não mereciam
                  receber as indenizações da empresa e que isso afasta os
                  “investimentos” da mineração na cidade.
                </p>
                <p>
                  Mariana, até então referência pelo patrimônio histórico e
                  paisagístico, passa a existir na mídia como o centro de uma
                  tragédia sem precedentes, reduzida a um mar de lama tóxico. A
                  parte urbana de Mariana, ocupada abruptamente por repórteres,
                  voluntários e voluntárias e centenas de atingidos e atingidas,
                  passa a exigir um retorno à normalidade. Assim, as vítimas do
                  desastre criminoso se tornaram não apenas o lembrete constante
                  dessa situação trágica, como também um óbice para a retomada
                  da vida cotidiana anterior ao rompimento. Ao invés de empatia,
                  os atingidos e atingidas tiveram poucos apoios para elaborar o
                  trauma, contaram sobretudo uns com os outros.
                </p>
                <p style="text-align: center">
                  <strong
                    >Autoria: Laís Jabace Maia<sup>6</sup> e Paula Pflüger
                    Zanardi<sup>7</sup></strong
                  >
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_20.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Lucas de Godoy/Jornal A Sirene, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>
            <p>
              KAHTUNI, Haydée Christinne. Dicionário sobre o pensamento de
              Sándor Ferenczi: uma contribuição à clínica psicanalítica
              contemporânea. Rio de Janeiro: Elsever; São Paulo: FAPESP, 2009.
            </p>
            <p>
              KUPERMANN, Daniel. Presença sensível: cuidado e criação na clínica
              psicanalítica. Rio de Janeiro: Civilização Brasileira, 2008.
            </p>
            <p>
              KUPERMANN, Daniel. Por que Ferenczi? 1. ed. São Paulo: Zagodoni,
              2019.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Para este site, não identificaremos os depoentes.
              Omitiremos os nomes e comunidades de origem das citações. Optamos
              por manter o sexo e a idade no momento da entrevista por ser uma
              informação genérica que contextualiza o leitor na experiência
              vivida pela criança ou adolescente, visto que corroboram o
              entendimento do que foi sucedendo nas etapas seguintes do
              desenvolvimento emocional destes jovens.
            </p>
            <p>
              <sup>2</sup> Freud desenvolveu duas teorias sobre o trauma. A
              grosso modo podemos dizer que, na primeira (1906), leva em
              consideração um evento traumático, em que existe um agente externo
              que o provocou e que funciona como uma experiência disruptiva,
              cujos efeitos só podemos observar depois. Na sua segunda teoria
              (1920), explicita os efeitos de um acontecimento traumático,
              deixando no psiquismo um excesso pulsional que é difícil de ser
              simbolizado, do psiquismo “dar conta”.
            </p>
            <p>
              <sup>3</sup> Sándor Ferenczi, pioneiro e grande psicanalista
              clínico, que elaborou a traumatogênese que aqui é utilizada para
              embasar essa breve análise.
            </p>
            <p>
              <sup>4</sup> A série de livros infantis “Bento: Passado, presente
              e futuro”, desenvolvidos pelos alunos do 4º ano da escola
              municipal de Bento Rodrigues em 2017, acompanhados pela professora
              Fátima Resende, foi uma iniciativa louvável, pois no projeto as
              crianças registram, testemunham e ajudam na elaboração desse
              momento tão difícil.
            </p>
            <p>
              <sup>5</sup> Houve diversos outros protestos em apoio à
              mineradora, como o 12 de março de 2016, que foi composto por 500 a
              mil pessoas, dentre elas prefeitos da região, vereadores e
              deputados, que pressionavam a Secretaria de Meio Ambiente e
              Desenvolvimento Sustentável (Semad) a liberar as licenças
              embargadas em função do rompimento da barragem de Fundão e
              pressionaram pela volta das atividades da mineradora na cidade.
              Fonte:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://www.otempo.com.br/cidades/manifestacao-pede-que-samarco-volte-a-operar-em-mariana-1.1256346"
                class="pink--text"
                >https://www.otempo.com.br/cidades/manifestacao-pede-que-samarco-volte-a-operar-em-mariana-1.1256346</a
              >

              e
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://mariana.mg.gov.br/noticia/3252/mariana-vai-as-ruas-em-prol-da-samarco"
                class="pink--text"
                >https://mariana.mg.gov.br/noticia/3252/mariana-vai-as-ruas-em-prol-da-samarco</a
              >
              .
            </p>
            <p>
              <sup>6</sup> Cientista Social (UFMG), mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Desde 2020 é
              Coordenadora Operacional da Assessoria Técnica aos Atingidos e
              Atingidas pelo Rompimento da Barragem do Fundão em Mariana pela
              Cáritas Brasileira Regional Minas Gerais.
            </p>
            <p>
              <sup>7</sup> Cientista Social pela UFSC, mestra em Preservação do
              Patrimônio Cultural pelo IPHAN. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Crianças e Adolescentes",
          href: "conteudos_tematicos_crianca_adolescente",
        },
      ],
    };
  },
};
</script>

<style scoped>
.stripe_background {
  position: relative;
  z-index: 10;
}

.stripe_background::before {
  content: "";
  position: absolute;
  top: 30%;
  width: 100%;
  height: 40%;
  background-color: #cd6b3e;
}

.section__mb {
  margin-bottom: 1em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_03.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 90%;
  padding: 2em 2em 2em 0;
}
</style>
